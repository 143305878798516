import { ref } from 'vue'
import { defineStore } from 'pinia'

import { listPaymentMethods } from '@rechargeapps/storefront-client'
import type { PaymentMethod } from '@rechargeapps/storefront-client'
import { useCustomerStore } from '../shopify'
import { useAuthStore } from './auth'

export const usePaymentMethodStore = defineStore('recharge/payment-method', () => {
  const { useSession } = useAuthStore()
  const { useCustomerAuthHeaders } = useCustomerStore()
  const { alert, handleError } = useAppStore()

  const loading = ref(false)
  const paymentMethods = ref<PaymentMethod[]>([])

  const loadPaymentMethods = async () => {
    loading.value = true
    const response = await listPaymentMethods(useSession(), { include: [ 'addresses' ] })
    paymentMethods.value = response.payment_methods
    loading.value = false
  }

  const usePaymentMethods = () => {
    if (paymentMethods.value.length === 0 && !loading.value) {
      loadPaymentMethods()
    }
    return paymentMethods
  }

  const update = async (payment_method: Partial<PaymentMethod>) => {
    const res = await fetch('/api/payment-method/update', {
      method: 'PUT',
      headers: useCustomerAuthHeaders(),
      body: JSON.stringify({ payment_method })
    })
    const data = await res.json()
    const index = paymentMethods.value.findIndex(i => i.id === data.id)
    if (index >= 0) {
      paymentMethods.value.splice(index, 1, data)
    }
    return data
  }

  const remove = async (id: number | string) => {
    try {
      const { error } = await useFetch('/api/payment-method/remove', {
        method: 'DELETE',
        headers: useCustomerAuthHeaders(),
        params: { id }
      })
      if (error.value) {
        throw new Error(error.value.data?.error)
      }
      paymentMethods.value = paymentMethods.value.filter(pm => pm.id !== id)
      navigateTo('/account')
      alert('We\'ve removed your payment method.')
    } catch (e) {
      handleError(e)
    }
  }

  const reset = () => {
    paymentMethods.value = []
    loading.value = false
  }

  return {
    loading,
    paymentMethods,
    loadPaymentMethods,
    usePaymentMethods,
    update,
    remove,
    reset
  }
})
